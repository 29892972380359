<template>
    <div class="box">
        <h2>
            Crefo Prüfungskonfiguration
            <small v-if="crefoCheck.id != ''">#{{ crefoCheck.id }}</small>
        </h2>
        <form @submit.prevent="save()">
            <div class="body">
                <div class="form-group form-float">
                    <input v-model="crefoCheck.label" v-validate="'required'" id="label" name="label" placeholder=" " type="text" />
                    <label for="title">Bezeichner</label>
                    <span class="error" v-show="errors.has('label')">{{ errors.first("label") }}</span>
                </div>
                <div class="form-group form-float form-select">
                    <select v-model="crefoCheck.product" v-validate="'required'" id="product" name="product" placeholder=" ">
                        <option value=""></option>
                        <option v-for="product in PRTYList.entries" v-bind:key="product.key" v-bind:value="product.key">
                            {{ product.designation }}
                        </option>
                    </select>
                    <label for="product">Produkt</label>
                    <span class="error" v-show="errors.has('product')">{{ errors.first("product") }}</span>
                </div>
                <div class="form-group form-float form-select">
                    <select v-model="crefoCheck.interest" v-validate="'required'" id="interest" name="interest" placeholder=" ">
                        <option value=""></option>
                        <option v-for="interest in LEINList.entries" v-bind:key="interest.key" v-bind:value="interest.key">
                            {{ interest.designation }}
                        </option>
                    </select>
                    <label for="interest">Legitimes Interesse</label>
                    <span class="error" v-show="errors.has('interest')">{{ errors.first("interest") }}</span>
                </div>
            </div>
            <h2>Regeln</h2>
            <div class="body">
                <div v-if="crefoCheck.product == 'PRTY-7'" class="form-group form-float">
                    <input v-model="crefoCheck.ecrefo" v-validate="'required|numeric'" id="ecrefo" name="ecrefo" placeholder=" " type="text" />
                    <label for="ecrefo">eCrefo Schwellwert</label>
                    <span class="error" v-show="errors.has('ecrefo')">{{ errors.first("ecrefo") }}</span>
                </div>

                <table class="crefoDmn">
                    <thead>
                        <th class="move"></th>
                        <th class="inputCol" v-for="(column, index) in crefoCheck.columns" v-bind:key="index">
                            <button type="button">
                                {{ partLabel(column) }}
                                <i @click="removePart(index)" class="remove fal fa-times"></i>
                            </button>
                        </th>
                        <th class="addPart">
                            <dropdown>
                                <template slot="trigger">
                                    <button type="button"><i class="fal fa-plus"></i></button>
                                </template>
                                <template slot="menu">
                                    <ul>
                                        <li v-for="(av, index) in partsAvailable" v-bind:key="index">
                                            <a href="#" @click.prevent="addPart(av)">{{ partLabel(av) }}</a>
                                        </li>
                                    </ul>
                                </template>
                            </dropdown>
                        </th>
                        <th class="result">Ergebnis</th>
                        <th class="remove"></th>
                    </thead>
                    <tbody>
                        <tr v-for="(rule, index) in crefoCheck.rules" v-bind:key="index">
                            <td class="move">
                                <div>
                                    <span @click="moveUp(index)" v-show="index != 0"><i class="fal fa-chevron-up"></i></span>
                                    <span @click="moveDown(index)" v-show="index != crefoCheck.rules.length - 1"><i class="fal fa-chevron-down"></i></span>
                                </div>
                            </td>
                            <td v-for="(part, pidx) in rule.parts" v-bind:key="pidx">
                                <div v-if="partType(pidx) == 'crefolist'" class="form-group form-float form-select no-error">
                                    <select v-model="rule.parts[pidx]" v-validate="'required'" :id="'part1_' + index + '_' + pidx" :name="'part1_' + index + '_' + pidx" placeholder=" ">
                                        <option value=""></option>
                                        <optgroup label="Einzeleintrag">
                                            <option v-for="entry in crefoList(pidx).entries" v-bind:key="entry.key" v-bind:value="'key_' + entry.key">
                                                {{ entry.designation }}
                                            </option>
                                        </optgroup>
                                        <optgroup label="Prüfungsliste">
                                            <option v-for="entry in crefoChecklists.results" v-bind:key="entry.id" v-bind:value="'list_' + entry.id"> {{ entry.label }} - list_{{ entry.id }} </option>
                                        </optgroup>
                                    </select>
                                    <span class="error" v-show="errors.has('part1_' + index + '_' + pidx)">{{ errors.first("part1_" + index + "_" + pidx) }}</span>
                                </div>
                                <div v-if="partType(pidx) == 'listonly'" class="form-group form-float form-select no-error">
                                    <select v-model="rule.parts[pidx]" v-validate="'required'" :id="'part2_' + index + '_' + pidx" :name="'part2_' + index + '_' + pidx" placeholder=" ">
                                        <option value=""></option>
                                        <option v-for="entry in crefoChecklists.results" v-bind:key="entry.id" v-bind:value="'list_' + entry.id">
                                            {{ entry.label }}
                                        </option>
                                    </select>
                                    <span class="error" v-show="errors.has('part2_' + index + '_' + pidx)">{{ errors.first("part2_" + index + "_" + pidx) }}</span>
                                </div>
                                <div v-if="partType(pidx) == 'intrange'" class="form-group form-cols no-error">
                                    <div class="form-float form-select">
                                        <select
                                            class="operator"
                                            @change="changeOperator(index, pidx, $event)"
                                            :value="decodePart(index, pidx).operator"
                                            v-validate="'required'"
                                            :id="'operator_' + index + '_' + pidx"
                                            :name="'operator_' + index + '_' + pidx"
                                        >
                                            <option value=""></option>
                                            <option value=">">&gt;</option>
                                            <option value="<">&lt;</option>
                                        </select>
                                        <span class="error" v-show="errors.has('operator_' + index + '_' + pidx)">{{ errors.first("operator_" + index + "_" + pidx) }}</span>
                                    </div>
                                    <div class="form-float">
                                        <input
                                            @input="changeOperand(index, pidx, $event)"
                                            :value="decodePart(index, pidx).operand"
                                            v-validate="'required|numeric'"
                                            :id="'operand_' + index + '_' + pidx"
                                            :name="'operand_' + index + '_' + pidx"
                                            placeholder=" "
                                            type="text"
                                        />
                                        <span class="error" v-show="errors.has('operand_' + index + '_' + pidx)">{{ errors.first("operand_" + index + "_" + pidx) }}</span>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td class="result">
                                <div class="form-group form-float form-select no-error">
                                    <select v-model="rule.result" v-validate="'required'" :id="'result_' + index" :name="'result_' + index" placeholder=" ">
                                        <option value=""></option>
                                        <option value="manual">Manuell</option>
                                        <option value="positive">Positiv</option>
                                        <option value="negative">Negativ</option>
                                    </select>
                                    <span class="error" v-show="errors.has('result_' + index)">{{ errors.first("result_" + index) }}</span>
                                </div>
                            </td>
                            <td class="remove"><i @click="removeRule(index)" class="delete fal fa-trash-alt"></i></td>
                        </tr>
                    </tbody>
                </table>
                <div class="addButton" @click="addRule()">Regel hinzufügen <i class="fal fa-plus"></i></div>
                <br style="clear: both;" />

                <hr />

                <div class="button-row">
                    <button type="submit" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    data() {
        return {
            type: "create",
            crefoCheck: {
                id: "",
                label: "",
                product: "",
                interest: "",
                rules: [],
                columns: [],
                ecrefo: ""
            },
            PRTYList: [],
            LEINList: [],
            crefoChecklists: [],
            TLList: [],
            BUDVList: [],
            PESTList: [],
            CROPList: [],
            LEFOList: [],
            NEFAList: [],
            ORSIList: [],
            PYMDList: [],
            RIJMList: []
        };
    },
    computed: {
        partsAvailable() {
            var available = this.allParts();

            // Remove already used
            for (var i = 0; i < this.crefoCheck.columns.length; i++) {
                var idx = available.indexOf(this.crefoCheck.columns[i]);
                if (idx > -1) {
                    // present
                    available.splice(idx, 1);
                }
            }

            return available;
        }
    },
    methods: {
        changeOperator(ruleIdx, partIdx, event) {
            var p = this.decodePart(ruleIdx, partIdx);
            p.operator = event.target.value;
            this.crefoCheck.rules[ruleIdx].parts[partIdx] = this.encodePart(p);
        },
        changeOperand(ruleIdx, partIdx, event) {
            var p = this.decodePart(ruleIdx, partIdx);
            p.operand = event.target.value;
            this.crefoCheck.rules[ruleIdx].parts[partIdx] = this.encodePart(p);
        },
        decodePart(ruleIdx, partIdx) {
            return {
                operator: this.crefoCheck.rules[ruleIdx].parts[partIdx].substring(6, 7),
                operand: this.crefoCheck.rules[ruleIdx].parts[partIdx].substring(7)
            };
        },
        encodePart(p) {
            return "value_" + p.operator + p.operand;
        },
        allParts() {
            if (this.crefoCheck.product === "PRTY-7") {
                // eCrefo
                return ["companystatus", "riskjudgement", "identificationnumber"];
            } else {
                return [
                    "solvencyindex",
                    "companystatus",
                    "trafficlightsolvency",
                    "trafficlightpaymentmode",
                    "negativefacts",
                    "negativetrafficlight",
                    "riskjudgement",
                    "balancesolvency",
                    "businessdevelopment",
                    "ordersituation",
                    "identificationnumber",
                    "paymentmode",
                    "creditopinion",
                    "legalform",
                    "ageofcompany",
                    "participationtrafficlight",
                    "wz2008"
                ];
            }
        },
        addRule() {
            var parts = [];
            for (var c = 0; c < this.crefoCheck.columns.length; c++) {
                parts.push("");
            }

            this.crefoCheck.rules.push({
                parts: parts,
                result: ""
            });
        },
        removeRule(index) {
            this.crefoCheck.rules.splice(index, 1);
        },
        moveUp(index) {
            this.swapRules(index - 1, index);
        },
        moveDown(index) {
            this.swapRules(index + 1, index);
        },
        swapRules(x, y) {
            var tmp = this.crefoCheck.rules[x];
            this.crefoCheck.rules[x] = this.crefoCheck.rules[y];
            Vue.set(this.crefoCheck.rules, y, tmp);
        },
        addPart(part) {
            this.crefoCheck.columns.push(part);
            for (var r = 0; r < this.crefoCheck.rules.length; r++) {
                this.crefoCheck.rules[r].parts.push("");
            }
        },
        removePart(index) {
            Vue.delete(this.crefoCheck.columns, index);
            for (var r = 0; r < this.crefoCheck.rules.length; r++) {
                Vue.delete(this.crefoCheck.rules[r].parts, index);
            }
        },
        partType(index) {
            var key = this.crefoCheck.columns[index];
            if (key === "ageofcompany" || key === "solvencyindex") {
                return "intrange";
            } else if (key === "identificationnumber" || key === "wz2008") {
                return "listonly";
            } else {
                return "crefolist";
            }
        },
        crefoList(index) {
            var key = this.crefoCheck.columns[index];
            switch (key) {
                case "balancesolvency":
                case "negativetrafficlight":
                case "participationtrafficlight":
                case "trafficlightpaymentmode":
                case "trafficlightsolvency":
                    return this.TLList;
                case "businessdevelopment":
                    return this.BUDVList;
                case "companystatus":
                    return this.PESTList;
                case "creditopinion":
                    return this.CROPList;
                case "legalform":
                    return this.LEFOList;
                case "negativefacts":
                    return this.NEFAList;
                case "ordersituation":
                    return this.ORSIList;
                case "paymentmode":
                    return this.PYMDList;
                case "riskjudgement":
                    return this.RIJMList;
                default:
                    return [];
            }
        },
        partLabel(key) {
            var all = {
                solvencyindex: "Bonitätsindex",
                companystatus: "Firmenstatus",
                trafficlightsolvency: "Bonitätsampel",
                trafficlightpaymentmode: "ZahlungsweiseAmpel",
                negativefacts: "Negativmerkmal",
                negativetrafficlight: "Negativ Ampel",
                riskjudgement: "Risikobewertung",
                balancesolvency: "Bilanzbonität",
                businessdevelopment: "Geschäftsentwicklung",
                ordersituation: "Auftragslage",
                identificationnumber: "Crefo-Nummer",
                paymentmode: "Zahlungsweise",
                creditopinion: "Krediturteil",
                legalform: "Aktuelle Rechtsform",
                ageofcompany: "Unternehmensalter",
                participationtrafficlight: "Beteiligung Ampel",
                wz2008: "WZ2008"
            };

            return all[key] ? all[key] : "";
        },
        create(columns, rules, ecrefo) {
            let request = {
                label: this.crefoCheck.label,
                product: this.crefoCheck.product,
                interest: this.crefoCheck.interest,
                ecrefo: ecrefo,
                rules: rules,
                columns: columns
            };
            this.$api.post("/crefo/check", request).then(
                response => {
                    // Config created
                    this.$snotify.success("Prüfungskonfiguration erstellt");
                    this.$router.push({ name: "crefo_config_edit", params: { id: response.data.data.id } });
                },
                () => {
                    // Create failed
                    this.$snotify.error("Speichern fehlgeschlagen");
                }
            );
        },
        edit(columns, rules, ecrefo) {
            let request = {
                label: this.crefoCheck.label,
                product: this.crefoCheck.product,
                interest: this.crefoCheck.interest,
                ecrefo: ecrefo,
                rules: rules,
                columns: columns
            };
            this.$api.put("/crefo/check/" + this.crefoCheck.id, request).then(
                () => {
                    // Config created
                    this.$snotify.success("Konfiguration aktualisiert");
                },
                () => {
                    // Create failed
                    this.$snotify.error("Aktualisierung fehlgeschlagen");
                }
            );
        },
        save() {
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                var ecrefo;
                if (this.crefoCheck.ecrefo !== "") {
                    try {
                        ecrefo = parseInt(this.crefoCheck.ecrefo, 10);
                    } catch (e) {
                        // Invalid ecrefo keep undefined
                    }
                }
                var rules = [];
                for (var r = 0; r < this.crefoCheck.rules.length; r++) {
                    let parts = [];
                    for (var p = 0; p < this.crefoCheck.rules[r].parts.length; p++) {
                        if (this.crefoCheck.rules[r].parts[p].startsWith("key_")) {
                            parts.push({
                                key: this.crefoCheck.rules[r].parts[p].substring(4)
                            });
                        } else if (this.crefoCheck.rules[r].parts[p].startsWith("list_")) {
                            parts.push({
                                checklist: this.crefoCheck.rules[r].parts[p].substring(5)
                            });
                        } else if (this.crefoCheck.rules[r].parts[p].startsWith("value_")) {
                            parts.push({
                                value: this.crefoCheck.rules[r].parts[p].substring(6)
                            });
                        } else {
                            parts.push({});
                        }
                    }
                    rules.push({
                        parts: parts,
                        result: this.crefoCheck.rules[r].result
                    });
                }

                if (this.crefoCheck.id === "") {
                    this.create(this.crefoCheck.columns, rules, ecrefo);
                } else {
                    this.edit(this.crefoCheck.columns, rules, ecrefo);
                }
            });
        },
        createdHook() {
            // Fetch url params data
            if (this.$route.params.id !== undefined) {
                this.crefoCheck.id = this.$route.params.id;
                this.type = "edit";
            } else {
                this.type = "create";
            }
            this.fetchData();
        },
        fetchData() {
            this.fetchCrefoCheck();
            this.fetchChecklists();
            this.fetchLists();
        },
        fetchCrefoCheck() {
            if (this.type !== "edit") {
                return;
            }

            this.$api.get("/crefo/check/" + this.crefoCheck.id).then(
                response => {
                    if (response.data.data.rules === null) {
                        response.data.data.rules = [];
                    }
                    for (let r = 0; r < response.data.data.rules.length; r++) {
                        if (response.data.data.rules[r].parts === null) {
                            response.data.data.rules[r].parts = [];
                        }

                        let parts = [];
                        for (let p = 0; p < response.data.data.rules[r].parts.length; p++) {
                            if (response.data.data.rules[r].parts[p].key != "") {
                                parts.push("key_" + response.data.data.rules[r].parts[p].key);
                            } else if (response.data.data.rules[r].parts[p].checklist != "") {
                                parts.push("list_" + response.data.data.rules[r].parts[p].checklist);
                            } else if (response.data.data.rules[r].parts[p].value != "") {
                                parts.push("value_" + response.data.data.rules[r].parts[p].value);
                            } else {
                                parts.push("");
                            }
                        }
                        response.data.data.rules[r].parts = parts;
                    }

                    this.crefoCheck = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Prüfung");
                }
            );
        },
        fetchChecklists() {
            let params = {
                page: 1,
                pageSize: 10000,
                q: ""
            };

            this.$api.get("/crefo/checklists", { params: params }).then(
                response => {
                    this.crefoChecklists = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Listen");
                }
            );
        },
        fetchLists() {
            let lists = {
                PRTY: "PRTYList",
                LEIN: "LEINList",
                TL: "TLList",
                BUDV: "BUDVList",
                PEST: "PESTList",
                CROP: "CROPList",
                LEFO: "LEFOList",
                NEFA: "NEFAList",
                ORSI: "ORSIList",
                PYMD: "PYMDList",
                RIJM: "RIJMList"
            };

            for (let key in lists) {
                this.$api.get("/crefo/keylist/" + key).then(
                    response => {
                        this[lists[key]] = response.data.data;
                    },
                    () => {
                        this.$snotify.error("Fehler beim Laden der Crefoliste");
                    }
                );
            }
        }
    },
    created() {
        this.createdHook();
    },
    watch: {
        "$route.params.id": function() {
            this.createdHook();
        },
        "crefoCheck.product": function() {
            if (this.crefoCheck.product !== "PRTY-7") {
                // Non eCrefo -> all parts allowed
                return;
            }

            // Check whether there are non allowed columns leftover
            for (var c = this.crefoCheck.columns.length - 1; c >= 0; c--) {
                if (this.allParts().indexOf(this.crefoCheck.columns[c]) === -1) {
                    // No allowed
                    this.removePart(c);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
table.crefoDmn {
    th {
        padding: 0 5px;
        position: relative;
    }

    th.result,
    th.remove,
    td.result,
    td.remove {
        vertical-align: middle;
        background: #5cb85c;
        color: #fff;
        border-color: #5cb85c;
    }

    th.result {
        width: 170px;
        padding: 1rem 0 1rem 10px;
    }

    td.result {
        width: 170px;
        padding: 0 0 0 10px;
    }

    th.remove {
        width: 30px;
    }

    th.inputCol {
        width: 20px;
    }

    th.inputCol,
    th.addPart {
        padding: 19px 5px 0 5px;
    }

    th button {
        display: block;
        position: relative;
        background-color: #f1f1f1;
        border-radius: 7px 7px 0 0;
        border: 1px solid #c2c2c2;
        border-bottom-width: 1px;
        border-bottom-width: 0;
        line-height: 25px;
        text-align: center;
        padding: 0 30px 0 10px;
        box-shadow: none;
        position: relative;
        bottom: 0;
        white-space: nowrap;

        i.remove {
            position: absolute;
            right: 10px;
            line-height: 25px;
        }
    }

    th.addPart button {
        padding-right: 10px;
    }

    th.move {
        width: 1.5rem;
    }

    td.move {
        padding: 0;

        div {
            display: flex;
            flex-direction: column;
            font-size: 0.7rem;
            padding: 0;
            align-items: center;

            span {
                cursor: pointer;
            }
        }
    }
}

div.addButton {
    background-color: #5cb85c;
    box-shadow: none;
    color: #fff;
    border: 1px solid #5cb85c;
    background: -moz-linear-gradient(#5cb85c, #449d44);
    background: -webkit-linear-gradient(#5cb85c, #449d44);
    background: -o-linear-gradient(#5cb85c, #449d44);
    background: -ms-linear-gradient(#5cb85c, #449d44);
    background: linear-gradient(#5cb85c, #449d44);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#5cb85c', endColorstr='#449d44');
    width: 200px;
    text-align: center;
    border-radius: 0 0 7px 7px;
    margin-bottom: 10px;
    margin-right: 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    float: right;
    cursor: pointer;

    &:after {
        content: "";
        clear: both;
    }
}

select.operator {
    width: 3rem;
}
</style>
